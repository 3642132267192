'use strict'

angular
  .module('template.list', [])
  .controller('ListController', ["$scope", "$filter", function ($scope, $filter) {
      $scope.pageAmount = 8;

      $scope.list1 = [
          { field1: 'Test 1', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) },
          { field1: 'Test 2', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) },
          { field1: 'Test 3', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) },
          { field1: 'Test 4', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) },
          { field1: 'Test 5', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) },
          { field1: 'Test 6', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) },
          { field1: 'Test 7', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) },
          { field1: 'Test 8', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) }
      ];

      $scope.list1Page = 3;
      $scope.list1Total = 104;

      $scope.list2 = [
          { field1: 'Test 1', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) },
          { field1: 'Test 2', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) },
          { field1: 'Test 3', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) },
          { field1: 'Test 4', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) },
          { field1: 'Test 5', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) },
          { field1: 'Test 6', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) },
          { field1: 'Test 7', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) },
          { field1: 'Test 8', field2: 'Lorem ipsum dolor sit amet', field3: 'Rilate niro lakem, consectetuer adipiscing', field4: randomDate(new Date(2012, 0, 1), new Date()) }
      ];

      $scope.list2Page = 5;
      $scope.list2Total = 74;

      $scope.decrement = function (pageNumber) {
          $scope[pageNumber]--;
      };

      $scope.increment = function (pageNumber) {
          $scope[pageNumber]++;
      };

      function randomDate(start, end) {
          return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
      };
  }])
